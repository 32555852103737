/* eslint-disable no-console */
/* eslint-disable no-unused-vars */

import 'moment/locale/ko'
import moment from 'moment-timezone/moment-timezone'
moment.tz.add("Asia/Seoul|KST|0|0");
moment.locale('ko');

export const sleep = (milliseconds) => {
  return new Promise(resolve => setTimeout(resolve, milliseconds))
}

export const calc_protein = async function(sex, weight, fatr, protein_multiplier) {
  let protein = 0;
  if ( sex == "M" ) { // 남성
    if ( fatr > 20 ) {
      protein = Number(weight*(100-fatr)/100*1.25*protein_multiplier).toFixed(1);
    } else {
      protein = Number(weight*protein_multiplier).toFixed(1);
    }
  } else { // 여성
    if ( fatr > 25 ) {
      protein = Number(weight*(100-fatr)/100*1.33*protein_multiplier).toFixed(1);
    } else {
      protein = Number(weight*protein_multiplier).toFixed(1);
    }
  }
  return Number(protein);
}

// export const calc_calory = function(weight, fatr, activity_factor, calories_delta, workout_hr, workout_cal) {
//   let tmp = weight*(100-fatr)/100*21.6+370;
//   let x = workout_hr; // temp workout time.
//   let y = workout_cal; // temp workout calories.
//   let calory = activity_factor*tmp-(tmp*x*activity_factor/24)+y+Number(calories_delta);
//   return Number(calory).toFixed(0);
// }

export const calc_workout = function(weight, fatr, workout) {
  let cal = 0;
  let hours = 0;
  for ( const each in workout ) {
    cal += (21.6*weight*(100-fatr)/100+370)/24*Number(workout[each].h)*Number(workout[each].f);
    hours += Number(workout[each].h);
  }
  return [ hours, cal ];
}

export const calc_food_protein = function(foods) {
  let protein = 0;
  for ( let food of foods ) {
    protein += ( food.protein * food.amount );
  }
  return Number(protein);
}

export const calc_food = function(total_calory, food) {
  let protein_percentage = Number(food.protein.kcal/Number(total_calory)*100).toFixed(1);

  let fat_percentage = Number(100 - food.carb.percentage - protein_percentage).toFixed(1);
  let fat_kcal = Number(fat_percentage/100*total_calory).toFixed(1);
  let fat_gram = Number(fat_kcal/9).toFixed(1);

  let carb_kcal = Number(food.carb.percentage/100*total_calory).toFixed(1);
  let carb_gram = Number(carb_kcal/4).toFixed(1);

  const t_ = {
    carb: {
      percentage: Number(food.carb.percentage),
      kcal: Number(carb_kcal),
      gram: Number(carb_gram),
    },
    protein: {
      percentage: Number(protein_percentage),
      kcal: Number(food.protein.kcal),
      gram: Number(food.protein.gram),
    },
    fat: {
      percentage: Number(fat_percentage),
      kcal: Number(fat_kcal),
      gram: Number(fat_gram),
    }
  }

  return t_;
}

import axios from 'axios';
import firebase from 'firebase/app';
import 'firebase/auth';

import api_conf from '../config/config.json';

export const get_basic_info = async function(email) {
  const id_token = await firebase.auth().currentUser.getIdToken();
  const user = await axios.get(
    api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/history', {
    params: { email: email },
  headers: {'id_token': id_token},
  });

  return user.data;
}

export const get_userone = async function(email, date) {
  const id_token = await firebase.auth().currentUser.getIdToken();
  const ret = await axios.get(
    api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/user/one', {
    params: { email: email },
    headers: {'id_token': id_token},
  });
  return ret.data;
}

export const get_activity = async function(email, date) {
  const id_token = await firebase.auth().currentUser.getIdToken();
  const activity = await axios.get(
    api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/activity/latest', {
    params: { email: email, date: date },
    headers: {'id_token': id_token},
  });

  return activity.data[0];
}

export const get_activity_latest = async function(email, date) {
  const id_token = await firebase.auth().currentUser.getIdToken();

  let activity = {};
  let max_tries = 0;
  const date_origin = date;
  let date_ = date;

  do {
    activity = await axios.get(
      api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/activity/latest', {
      params: { email: email, date: date_ },
      headers: {'id_token': id_token},
    });

    if ( max_tries > 7 ) break;
    max_tries++;

    date_ = moment(date_, "YYYY-MM-DD").subtract(1, 'days').format("YYYY-MM-DD");

  } while ( activity.data[0] === undefined || (activity.data[0] !== undefined && activity.data[0].activity.length == 0 ));

  if ( activity.data[0] !== undefined && activity.data[0].activity.length > 0 ) {

    await axios.post(
      api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/activity', {
      activity: activity.data[0].activity,
      date: date_origin,
      email: email,
    }, {
      headers: {'id_token': id_token},
    });
  }

  return activity.data[0];
}


export const post_activity = async function(email, activity, date) {
  const id_token = await firebase.auth().currentUser.getIdToken();
  await axios.post(
    api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/activity', {
    email: email,
    date: date,
    activity: activity,
  }, {
    headers: {'id_token': id_token},
  });

}

export const calc_tdee = function(weight, fat, activity) {

  // 활동 칼로리 계산
  let basic_calory_ = Math.round(Number(weight * (100 - fat)/100 * 21.6 + 370)/10)*10;

  let t_ = 0;
  for ( let each of activity ) {
    t_ += each.time * each.par;
  }
  t_ = t_ / 24;

  let calculated_calory_ = Math.round(Number(basic_calory_ * t_)/10)*10;

  if ( calculated_calory_ == 0 )
    calculated_calory_ = basic_calory_;

  return [ basic_calory_, calculated_calory_ ];
}

// custom activity

export const get_custom_activity = async function(email) {
  const id_token = await firebase.auth().currentUser.getIdToken();
  const activity = await axios.get(
    api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/activity/custom/', {
    params: { email: email },
    headers: {'id_token': id_token},
  });

  return activity.data;
}

export const post_custom_activity = async function(email, activity) {
  const id_token = await firebase.auth().currentUser.getIdToken();
  await axios.post(
    api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/activity/custom/', {
    email: email,
    name: activity.name,
    time: activity.time,
    par: activity.par,
  }, {
    headers: {'id_token': id_token},
  });

}

export const delete_custom_activity = async function(email, custom_id) {
  const id_token = await firebase.auth().currentUser.getIdToken();
  await axios.delete(
    api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/activity/custom/', {
      data: {
        email: email,
        custom_id: custom_id,
      },
      headers: {'id_token': id_token},
  });

}

export const get_foodmacro = async function(email, date) {
  const id_token = await firebase.auth().currentUser.getIdToken();
  const foodmacro = await axios.get(
    api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/foodmacro', {
    params: { email: email, date: date },
    headers: {'id_token': id_token},
  });

  return foodmacro.data[0];
}

export const get_history_one = async function(email, date) {
  const id_token = await firebase.auth().currentUser.getIdToken();
  const ret = await axios.get(
    api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/history/one', {
    params: { email: email, date: date },
    headers: {'id_token': id_token},
  });

  return ret.data[0];
}

export const get_history_latest = async function(email) {
  const id_token = await firebase.auth().currentUser.getIdToken();
  const ret = await axios.get(
    api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/history/latest', {
    params: { email: email },
    headers: {'id_token': id_token},
  });

  return ret.data[0];
}

export const post_history = async function(email, date, data) {
  const id_token = await firebase.auth().currentUser.getIdToken();
  await axios.post(
    api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/history/', {
    email: email,
    date: date,
    weight: data.weight,
    fat: data.fat,
    muscle: data.muscle,
    fatrate_wanted: data.fatrate_wanted,
    protein: data.protein,
  }, {
    headers: {'id_token': id_token},
  });
}

export const get_supplement_latest = async function(email) {
  const id_token = await firebase.auth().currentUser.getIdToken();
  const ret = await axios.get(
    api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/supplement/latest', {
    params: { email: email},
    headers: {'id_token': id_token},
  });

  return ret.data;
}


export const get_custom_food = async function(email, type) {
  const id_token = await firebase.auth().currentUser.getIdToken();
  const activity = await axios.get(
    api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/food/custom/', {
    params: { email: email, type: type },
    headers: {'id_token': id_token},
  });

  return activity.data;
}

export const post_custom_food = async function(email, food) {
  const id_token = await firebase.auth().currentUser.getIdToken();
  await axios.post(
    api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/food/custom/', {
    email: email,
    name: food.name,
    calory: food.calory,
    carb: food.carb,
    protein: food.protein,
    fat: food.fat,
    subfoodlist: food.subfoodlist,
    type: food.type,
    standard: food.standard,
    unit: food.unit,
  }, {
    headers: {'id_token': id_token},
  });

}


export const edit_custom_food = async function(email, food) {
  const id_token = await firebase.auth().currentUser.getIdToken();
  await axios.post(
    api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/food/custom/edit', {
    email: email,
    name: food.name,
    calory: food.calory,
    carb: food.carb,
    protein: food.protein,
    fat: food.fat,
    // subfoodlist: food.subfoodlist,
    type: food.type,
    standard: food.standard,
    unit: food.unit,
  }, {
    headers: {'id_token': id_token},
  });

}

export const delete_custom_food = async function(email, custom_id) {
  const id_token = await firebase.auth().currentUser.getIdToken();
  await axios.delete(
    api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/food/custom/', {
      data: {
        email: email,
        custom_id: custom_id,
      },
      headers: {'id_token': id_token},
  });

}