import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  mode: 'history',
  linkActiveClass: 'is-active',
  linkExactActiveClass: 'is-exact-active',
  routes: [
    { path: '/', component: () => import('@/components/Intro') },
    { path: '/main', component: () => import('@/components/Index') },
    { path: '/about', component: () => import('@/components/About') },
    { path: '/Purchase_info', component: () => import('@/components/User/Purchase_info') },
    { path: '/food/history', component: () => import('@/components/User/FoodHistory') },
    { path: '/user/settings', component: () => import('@/components/User/Settings') },
    { path: '/user/login', component: () => import('@/components/User/Login') },
    { path: '/user/join', component: () => import('@/components/User/Join') },
    { path: '/weight', component: () => import('@/components/Weight') },
    { path: '/supplement', component: () => import('@/components/Supplement') },
    { path: '/youtube', component: () => import('@/components/Youtube') },
    { path: '/loading', component: () => import('@/components/Weight/Loading') },
    { path: '/cycling', component: () => import('@/components/Weight/Cycling') },

    { path: '/agreement', component: () => import('@/components/User/Agreement') },
    { path: '/user/private', component: () => import('@/components/User/Private') },
    { path: '/user/terms', component: () => import('@/components/User/Terms') },
    { path: '/naver/auth', component: () => import('@/components/Naver') },
//    { path: '/board/:bid/:id', component: () => import('@/components/Board_View') },
//    { path: '/write/:bid/', component: () => import('@/components/Board_Write') },

//    { path: '/user/login', component: () => import('@/components/User/Login') },
//    { path: '/user/join', component: () => import('@/components/User/Join') },

    { path: '/admin/order', component: () => import('@/components/Admin/Index') },
    { path: '/admin/food', component: () => import('@/components/Admin/Food') },
    { path: '/admin/member', component: () => import('@/components/Admin/Member') },
    { path: '/admin/notice', component: () => import('@/components/Admin/Notice') },
    { path: '/admin/supplement', component: () => import('@/components/Admin/Supplement') },
    { path: '/admin/chicken', component: () => import('@/components/Admin/Chicken') },
    { path: '/admin/bug', component: () => import('@/components/Admin/Bug') },
    { path: '/admin/youtube', component: () => import('@/components/Admin/Youtube') },
    { path: '/admin/supplement/choice', component: () => import('@/components/Admin/Supplement_Choice') },
    { path: '/admin/alarm', component: () => import('@/components/Admin/Alarm') },
    { path: '/admin/team', component: () => import('@/components/Admin/Team') },
    { path: '/admin/fund', component: () => import('@/components/Admin/Fund') },
  ]
})
