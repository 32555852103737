<template>
  <v-btn text @click="onLogout" :class="color" v-if="user.login" rounded >
    로그아웃
  </v-btn>
  <v-btn text to="/user/login" :class="color" v-else rounded>
    로그인
  </v-btn>
</template>

<script>
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import firebase from 'firebase/app';
import 'firebase/auth';

import { mapGetters } from 'vuex'
import { mapActions } from 'vuex'

export default {
  props: {
    color: String,
  },
  data: () => ({
  }),
  computed: {
    ...mapGetters({
      user: "user",
      appbarcolor: "appbarcolor",
    })
  },
  methods: {
    onLogout: async function() {
      const res = await firebase.auth().signOut();

      await this.$store.dispatch("fetchUser", null);
      await this.$store.dispatch("fetchLvl", 10);

      await this.clearAll();

      if(this.$router.currentRoute.path != "/")
        this.$router.push({ path: "/" });
    },
    clearAll: async function() {
      await this.$store.dispatch('fetchWeight', 0);
      await this.$store.dispatch('fetchFat', 0);
      await this.$store.dispatch('fetchMuscle', 0);
      await this.$store.dispatch('fetchFatrateWanted', 0);
      await this.$store.dispatch('fetchProteinMultiplier', 1.6);
      await this.$store.dispatch('fetchCaloryDelta', 0);
      await this.$store.dispatch('fetchActivity', []);
      await this.$store.dispatch('fetchCaloryCalculated', 0);
      await this.$store.dispatch('fetchFood', {
        carb: {
          percentage: 50,
          kcal: 0,
          gram: 0,
        },
        fat: {
          percentage: 50,
          kcal: 0,
          gram: 0,
        },
        protein: {
          percentage: 50,
          kcal: 0,
          gram: 0,
        },
      });
      await this.$store.dispatch('fetchSupplement', []);
    },
  },
}
</script>

<style scoped>
</style>
