<template>
  <Home/>
</template>

<script>
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import firebase from 'firebase/app';
import 'firebase/auth';
import axios from 'axios';

import api_conf from './config/config.json';
import {
  sleep,
  calc_food,
  calc_protein,
  calc_tdee,
  get_activity,
  get_activity_latest,
  get_userone,
  get_foodmacro,
  get_supplement_latest,
  get_history_one,
  get_history_latest,
  post_history,
} from './common/functions.js';

import { mapGetters } from 'vuex'

import Home from './components/Home';

export default {
  name: 'App',

  components: {
    Home,
  },

  computed: {
    ...mapGetters({
      user: "user",
      mobile: "mobile",
      limited: "limited",
      data: "data",
      lvl: "lvl",
      appbarcolor: "appbarcolor",
      dietstatus: "dietstatus",
      userloading: "userloading",

      date: "date",
    }),
    getUserData: function() {
      return this.$store.getters.user.data;
    },
    getUserLoading: function() {
      return this.$store.getters.userloading;
    },
    getDate: function() {
      return this.$store.getters.date;
    },
    getFirebaseUser: function() {
      return this.$store.getters.firebase_user;
    },
    getWeight: function() {
      return this.$store.getters.data.weight;
    },
    getFat: function() {
      return this.$store.getters.data.fat;
    },
    getMuscle: function() {
      return this.$store.getters.data.muscle;
    },
    getProteinMultiplier: function() {
      return this.$store.getters.data.protein_multiplier;
    },
  },

  data: () => ({
    activity: undefined,
    userone: undefined,
    foodmacro: undefined,
    supplement: undefined,
  }),

  watch: {
    getUserLoading: async function(v, oldv) {
      // if ( v == false )
      //   await this.load_init();
    },
    getDate: async function(v, oldv) {
      console.log(v, oldv);
      if ( v !== '' && oldv !== '') {
        await this.load_init_data();
        await this.load_init();
      }
    },
    getFirebaseUser: async function(v, oldv) {
      await this.load_init_data();
      await this.load_init();
    },
    getWeight: async function(v, oldv) {
      await this.build_calory();
    },
    getFat: async function(v, oldv) {
      await this.build_calory();
    },
    getMuscle: async function(v, oldv) {
      await this.build_calory();
    },
    getProteinMultiplier: async function(v, oldv) {
      await this.build_calory();
    },
  },

  created: async function () {
    this.$vuetify.theme.dark = false;

    firebase.auth().onAuthStateChanged(async user => {
      await this.$store.dispatch("fetchUser", user);
      if (user !== null) {
        const id_token = await firebase.auth().currentUser.getIdToken(true);

        await this.$store.dispatch("postFirebaseUser", firebase.auth().currentUser);

        const user_ = await axios.get(
          api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/user/one', {
          params: { email: user.email },
          headers: {'id_token': id_token},
        });

        if ( user_.data === null ) {
          this.$store.dispatch("fetchLvl", 10);
          this.$router.push({ path: "/agreement" });
        } else {
          this.$store.dispatch("fetchLvl", user_.data.lvl);
          this.$store.dispatch("fetchSex", user_.data.sex);
        }

        this.$store.dispatch('fetchUserLoading', false);
      } else {
        this.$store.dispatch('fetchUserLoading', false);
      }

    });
  },

  beforeDestroy () {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.onResize, { passive: true });
      window.removeEventListener('scroll', this.onScroll, { passive: true });
    }
  },
  beforeUpdate: async function() {
    // await this.load_init();
  },
  mounted: async function () {
    // 초기 날짜 정보 입력
    await this.$store.dispatch('postDate', this.$moment().format("YYYY-MM-DD"));

    this.onResize();
    this.onScroll();
    window.addEventListener('resize', this.onResize, { passive: true });
    window.addEventListener('scroll', this.onScroll, { passive: true });
  },
  methods: {

    load_init_data: async function() {

      // get activity
      this.activity = await get_activity_latest(this.user.data.email, this.date);
      // get user one
      this.userone = await get_userone(this.user.data.email);
      // get foodmacro
      this.foodmacro = await  get_foodmacro(this.user.data.email, this.date);
      // get supplement
      this.supplement = await get_supplement_latest(this.user.data.email);
    },

    load_init: async function() {
      // basic data load
      if ( this.user.data !== null) {

        if ( this.userone !== undefined) {

          this.$store.dispatch("fetchDietStatus", this.userone.dietstatus);
          if ( this.userone.height === undefined
            || this.userone.height <= 0
            || this.userone.height == null ) {
            alert('마이페이지에서 키를 입력하세요.')
          } else {
            await this.$store.dispatch('postHeight', this.userone.height);
          }
        }

        // 이전 정보가 있는지 확인
        let history = await get_history_one(this.user.data.email, this.date);

        {
          const today = this.$moment().format("YYYY-MM-DD");
          if ( today <= this.date && history === undefined) {
            // 기본정보 전날에서 가져오기
            const latest_ = await get_history_latest(this.user.data.email);

            // 새로 가입한 경우는 이부분 진행하지 않는다.
            if ( latest_ !== undefined ) {

              history = latest_;

              this.$store.dispatch('fetchWeightHistory', history);
              this.$store.dispatch('fetchWeight', history.weight);
              this.$store.dispatch('fetchFat', history.fat);
              this.$store.dispatch('fetchMuscle', history.muscle);
              this.$store.dispatch('fetchFatrateWanted', history.fatrate_wanted);
              this.$store.dispatch('fetchProteinMultiplier', (history.protein !== undefined) ? history.protein:1.6);
              await this.build_calory();
              await this.build_foodmacro();
              await this.build_supplement();
              await this.reload_info();

              await post_history(this.user.data.email, this.date, history);
            }
          } else if ( history === undefined ) {
            await this.clearAll();
          } else {
            this.$store.dispatch('fetchWeightHistory', history);
            this.$store.dispatch('fetchWeight', history.weight);
            this.$store.dispatch('fetchFat', history.fat);
            this.$store.dispatch('fetchMuscle', history.muscle);
            this.$store.dispatch('fetchFatrateWanted', history.fatrate_wanted);
            this.$store.dispatch('fetchProteinMultiplier', (history.protein !== undefined) ? history.protein:1.6);
            await this.build_calory();
            await this.build_foodmacro();
            await this.build_supplement();
            await this.reload_info();
          }
        }

        await this.$store.dispatch("fetchLoaded", true);
      }
    },

    build_calory: async function() {
      await this.load_init_data();
      // 기초대사량
      let basic_calory_ = Math.round(Number(this.data.weight * (100 - this.data.fat)/100 * 21.6 + 370)/10)*10;
      await this.$store.dispatch('postBaseCalory', basic_calory_);

      let calculated_calory_ = 0;

      // 활동 칼로리 계산
      if ( this.activity === undefined ) {
        await this.$store.dispatch('fetchActivity', []);
        await this.$store.dispatch('postTDEE', 0);
      } else {
        // await this.$store.dispatch('fetchActivity', this.activity.activity);

        await this.$store.dispatch('fetchCaloryDelta', this.activity.calory_delta);

        const [ basic_calory, calory_calculated ]= calc_tdee(this.data.weight, this.data.fat, this.activity.activity);
        await this.$store.dispatch('postBaseCalory', basic_calory);
        await this.$store.dispatch('postTDEE', calory_calculated);

      }
    },

    build_foodmacro: async function() {
      if ( this.foodmacro == undefined ) return;
      let tmp = {
        carb: {
          percentage: this.foodmacro.carb_percentage,
        },
      };
      this.$store.dispatch('fetchFood', tmp);
    },

    build_supplement: async function() {
      if (this.supplement.length>0) {
        let latest_supplement = this.supplement[this.supplement.length-1];
        this.$store.dispatch('fetchSupplement', latest_supplement.foodlist);
      }
    },

    reload_info: async function() {
      let gram_ = await calc_protein(this.user.sex, this.data.weight, this.data.fat, this.data.protein_multiplier);
      let kcal_ = gram_*4;

      await this.$store.dispatch('fetchFood', {
        protein: {
          gram: gram_,
          kcal: kcal_,
        },
      });
      const calc_ = calc_food(this.tdee, this.data.food);
      await this.$store.dispatch('fetchFood', calc_);
    },

    clearAll: async function() {
      await this.$store.dispatch('fetchWeight', 0);
      await this.$store.dispatch('fetchFat', 0);
      await this.$store.dispatch('fetchMuscle', 0);
      await this.$store.dispatch('fetchFatrateWanted', 0);
      await this.$store.dispatch('fetchProteinMultiplier', 1.6);
      await this.$store.dispatch('fetchCaloryDelta', 0);
      await this.$store.dispatch('fetchActivity', []);
      await this.$store.dispatch('fetchCaloryCalculated', 0);
      await this.$store.dispatch('fetchFood', {
        carb: {
          percentage: 50,
          kcal: 0,
          gram: 0,
        },
        fat: {
          percentage: 50,
          kcal: 0,
          gram: 0,
        },
        protein: {
          percentage: 50,
          kcal: 0,
          gram: 0,
        },
      });
      await this.$store.dispatch('fetchSupplement', []);
    },
    onResize: function() {
      let ismobile = (window.innerWidth < 600) || (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
      this.$store.dispatch("fetchMobile", ismobile);

      let islimited = (window.innerWidth > 1200);
      this.$store.dispatch("fetchLimited", islimited);
    },
    onScroll: function() {
      this.scroll = window.scrollY;
    },

  },
};
</script>
