import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);
/* eslint-disable no-console */

export default new Vuex.Store({
  state: {
    id_token: "",
    date: "",
    firebase_user: null,
    height: 0,
    mobile: false,
    limited: false,
    is_loaded: false,
    foodlist: [],
    guide: 0,
    diet: {

    },
    base_calory: 0,
    tdee: 0,
    activity: [],
    data: {
      muscle:0,
      fatrate_wanted: 0,
      is_new: false,
      is_previous: false,
      weight: 0,
      fat: 0,
      protein_multiplier: 1.6,
      protein_multipliers: [1.6, 2.0],
      calory_calculated: 0,
      calory_delta: 0,
      activity_selected: 1.4,
      activities: [{
        text: '활동 없음',
        value: 1.4
      }, {
        text: '앉아 있는 업무',
        value: 1.5,
      }, {
        text: '서있는 업무',
        value: 1.75,
      }, {
        text: '활동적인 업무',
        value: 2,
      }],
      activity: [],
      workout: {
        h1: { h:0, f:2 },
        h2: { h:0, f:3.5 },
        h3: { h:0, f:5 },
        h4: { h:0, f:6.5 },
      },
      food: {
        carb: {
          percentage: 50,
          kcal: 0,
          gram: 0,
        },
        fat: {
          percentage: 20,
          kcal: 0,
          gram: 0,
        },
        protein: {
          percentage: 30,
          kcal: 0,
          gram: 0,
        },
      },
      weight_history: [],
      dietary: {
        proteintotal: 0,
        proteinfromfoods: 0,
        proteinremains: 0,
        proteinafterworkout: 20,
        proteinfromrice: 0,
        bowlofrice_actually: 0,
        bowlofrice: 0,
      },
      supplements: [],
    },
    userloading: true,
    user: {
      login: false,
      data: null,
      sex: "",
      dietstatus: 0,
    },
    lvl: 0,
  },
  getters: {
    guide(state){
      return state.guide
    },
    height(state){
      return state.height
    },
    base_calory(state){
      return state.base_calory
    },
    tdee(state) {
      return state.tdee
    },
    firebase_user(state) {
      return state.firebase_user
    },
    id_token(state) {
      return state.id_token
    },
    user(state) {
      return state.user
    },
    userloading(state) {
      return state.userloading
    },
    dietstatus(state) {
      return state.user.dietstatus
    },
    lvl(state) {
      return state.lvl
    },
    sex(state) {
      return state.user.sex
    },
    data(state) {
      return state.data
    },
    activity(state) {
      return state.activity
    },
    mobile(state) {
      return state.mobile
    },
    limited(state) {
      return state.limited
    },
    loaded(state) {
      return state.is_loaded
    },
    foodlist(state) {
      return state.foodlist
    },
    date(state) {
      return state.date
    },
    time(state) {
      return state.time
    }
  },
  mutations: {
    SET_GUIDE(state, guide) {
      state.guide = guide;
    },
    SET_HEIGHT(state, height) {
      state.height = height;
    },
    SET_BASE_CALORY(state, base_calory) {
      state.base_calory = base_calory;
    },
    SET_TDEE(state, tdee) {
      state.tdee = tdee;
    },
    SET_FIREBASE_USER(state, firebase_user) {
      state.firebase_user = firebase_user;
    },
    SET_ID_TOKEN(state, id_token) {
      state.id_token = id_token;
    },
    SET_USERLOADING(state, userloading) {
      state.userloading = userloading;
    },
    SET_FOODLIST(state, foodlist) {
      state.foodlist = foodlist;
    },
    SET_SEX(state, sex) {
      state.user.sex = sex;
    },
    SET_DATE(state, date) {
      state.date = date;
    },
    SET_TIME(state, time) {
      state.time = time;
    },
    SET_DIETSTATUS(state, dietstatus) {
      state.user.dietstatus = dietstatus;
    },
    SET_LOADED(state, loaded) {
      state.is_loaded = loaded;
    },
    SET_NEW(state, newone) {
      state.new = newone;
    },
    SET_MOBILE(state, mobile) {
      state.mobile = mobile;
    },
    SET_LIMITED(state, limited) {
      state.limited = limited;
    },
    SET_DIETARY(state, dietary) {
      if ( dietary.proteintotal !== undefined )
        state.data.dietary.proteintotal = dietary.proteintotal;
      if ( dietary.proteinfromfoods !== undefined )
        state.data.dietary.proteinfromfoods = dietary.proteinfromfoods;
      if ( dietary.proteinremains !== undefined )
        state.data.dietary.proteinremains = dietary.proteinremains;
      if ( dietary.proteinafterworkout !== undefined )
        state.data.dietary.proteinafterworkout = dietary.proteinafterworkout;
      if ( dietary.proteinfromrice !== undefined )
        state.data.dietary.proteinfromrice = dietary.proteinfromrice;
      if ( dietary.bowlofrice_actually !== undefined )
        state.data.dietary.bowlofrice_actually = dietary.bowlofrice_actually;
      if ( dietary.bowlofrice !== undefined )
        state.data.dietary.bowlofrice = dietary.bowlofrice;
    },
    SET_LOG_IN(state, value) {
      state.user.login = value;
    },
    SET_USER(state, data) {
      state.user.data = data;
    },
    SET_LVL(state, lvl) {
      state.lvl = lvl;
    },
    SET_ISPREVIOUS(state, is_previous) {
      state.data.is_previous = is_previous;
    },
    SET_WEIGHTHISTORY(state, weight_history) {
      state.data.weight_history = weight_history;
    },
    SET_SUPPLEMENT(state, supplement) {
      state.data.supplement = supplement
    },
    SET_WEIGHT(state, weight) {
      state.data.weight = weight;
    },
    SET_FAT(state, fat) {
      state.data.fat = fat;
    },
    SET_MUSCLE(state, muscle) {
      state.data.muscle = muscle;
    },
    SET_FATRATEWANTED(state, fatrate_wanted) {
      state.data.fatrate_wanted = fatrate_wanted;
    },
    SET_PROTEINMULTIPLIER(state, protein_multiplier) {
      state.data.protein_multiplier = protein_multiplier;
    },
    SET_CALORYDELTA(state, calory_delta) {
      state.data.calory_delta = calory_delta;
    },
    SET_CALORYCALCULATED(state, calory_calculated) {
      state.data.calory_calculated = calory_calculated;
    },
    SET_ACTIVITY(state, activity) {
      state.activity = activity;
    },
    SET_ACTIVITYSELECTED(state, activity_selected) {
      state.data.activity_selected = activity_selected;
    },
    SET_WORKOUT(state, workout) {
      if ( workout.h1 !== undefined ) {
        state.data.workout.h1.h = workout.h1;
      }
      if ( workout.h2 !== undefined ) {
        state.data.workout.h2.h = workout.h2;
      }
      if ( workout.h3 !== undefined ) {
        state.data.workout.h3.h = workout.h3;
      }
      if ( workout.h4 !== undefined ) {
        state.data.workout.h4.h = workout.h4;
      }
    },
    SET_FOOD(state, food) {
      if ( food.carb !== undefined ) {
        if ( food.carb.percentage !== undefined ) {
          state.data.food.carb.percentage = food.carb.percentage
        }
        if ( food.carb.kcal !== undefined ) {
          state.data.food.carb.kcal = food.carb.kcal;
        }
        if ( food.carb.gram !== undefined ) {
          state.data.food.carb.gram = food.carb.gram;
        }
      }
      if ( food.protein !== undefined ) {
        if ( food.protein.percentage !== undefined ) {
          state.data.food.protein.percentage = food.protein.percentage
        }
        if ( food.protein.kcal !== undefined ) {
          state.data.food.protein.kcal = food.protein.kcal
        }
        if ( food.protein.gram !== undefined ) {
          state.data.food.protein.gram = food.protein.gram
        }
      }
      if ( food.fat !== undefined ) {
        if ( food.fat.percentage !== undefined ) {
          state.data.food.fat.percentage = food.fat.percentage
        }
        if ( food.fat.kcal !== undefined ) {
          state.data.food.fat.kcal = food.fat.kcal
        }
        if ( food.fat.gram !== undefined ) {
          state.data.food.fat.gram = food.fat.gram
        }
      }
    },
  },
  actions: {
    postGuide( { commit }, guide ) {
      commit("SET_GUIDE", guide);
    },
    postHeight( { commit }, height ) {
      commit("SET_HEIGHT", height);
    },
    postBaseCalory( { commit }, base_calory ) {
      commit("SET_BASE_CALORY", base_calory);
    },
    postTDEE( { commit }, tdee ) {
      commit("SET_TDEE", tdee);
    },
    postFirebaseUser( { commit }, firebase_user ) {
      commit("SET_FIREBASE_USER", firebase_user);
    },
    postIDToken( { commit }, id_token ) {
      commit("SET_ID_TOKEN", id_token);
    },
    postDate( { commit }, date ) {
      commit("SET_DATE", date);
    },
    fetchUserLoading( { commit }, userloading ) {
      commit("SET_USERLOADING", userloading);
    },
    fetchFoodlist({ commit }, foodlist ) {
      commit("SET_FOODLIST", foodlist);
    },
    fetchSex({ commit }, sex) {
      commit("SET_SEX", sex);
    },
    fetchDietStatus({ commit }, dietstatus) {
      commit("SET_DIETSTATUS", dietstatus);
    },
    fetchLoaded({ commit }, loaded) {
      commit("SET_LOADED", loaded);
    },
    fetchNew({ commit }, newone) {
      commit("SET_NEW", newone);
    },
    fetchMobile({ commit }, mobile) {
      commit("SET_MOBILE", mobile);
    },
    fetchLimited({ commit }, limited) {
      commit("SET_LIMITED", limited);
    },
    fetchSupplement({ commit }, supplement) {
      commit("SET_SUPPLEMENT", supplement);
    },
    fetchDietary({ commit }, dietary) {
      commit("SET_DIETARY", dietary);
    },
    fetchLvl({ commit }, lvl) {
      commit("SET_LVL", lvl);
    },
    fetchIsprevious({ commit }, is_previous) {
      commit("SET_ISPREVIOUS", is_previous);
    },
    fetchWeightHistory({ commit }, weight_history) {
      commit("SET_WEIGHTHISTORY", weight_history);
    },
    fetchWeight({commit}, weight) {
      commit("SET_WEIGHT", weight);
    },
    fetchFat({commit}, fat) {
      commit("SET_FAT", fat);
    },
    fetchMuscle({commit}, muscle) {
      commit("SET_MUSCLE", muscle);
    },
    fetchFatrateWanted({commit}, fatrate_wanted) {
      commit("SET_FATRATEWANTED", fatrate_wanted);
    },
    fetchProteinMultiplier({commit}, protein_multiplier) {
      commit("SET_PROTEINMULTIPLIER", protein_multiplier);
    },
    fetchCaloryDelta({commit}, calory_delta) {
      commit("SET_CALORYDELTA", calory_delta);
    },
    fetchCaloryCalculated({commit}, calory_calculated) {
      commit("SET_CALORYCALCULATED", calory_calculated);
    },
    fetchActivity({commit}, activity) {
      commit("SET_ACTIVITY", activity);
    },
    fetchActivitySelected({commit}, activity_selected) {
      commit("SET_ACTIVITYSELECTED", activity_selected);
    },
    fetchWorkout({commit}, workout) {
      commit("SET_WORKOUT", workout);
    },
    fetchFood({commit}, food) {
      commit("SET_FOOD", food);
    },
    fetchUser({ commit }, user) {
      commit("SET_LOG_IN", user !== null);
      if (user) {
        commit("SET_USER", {
          email: user.email,
          uid: user.uid,
        });
      } else {
        commit("SET_USER", null);
      }
    }
  }
});
