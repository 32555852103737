<template>
  <v-app id="keep">
    <v-app-bar app flat clipped-left dense class="apptopbar" elevation=0 :color="ismain()" >
      <v-app-bar-nav-icon @click="drawer = !drawer" v-if="lvl==1" />
      <!--<v-spacer v-if="mobile"/>-->
      <router-link to="/">
        <span class="ml-3 mr-5 font-weight-bold" :class="ismain2().color">
          동면중 {{ data.ismobile }}
        </span>
      </router-link>
      <v-toolbar-items v-if="!mobile">
        <v-btn elevation=false class="appmenu" :ripple="false" :class="ismain2().color" to="/main">체중관리</v-btn>
        <v-btn elevation=false class="appmenu" :ripple="false" :class="ismain2().color" to="/weight">운동도우미</v-btn>
        <v-btn elevation=false class="appmenu" :ripple="false" :class="ismain2().color" to="/supplement">보충제, 닭가슴살 추천</v-btn>
        <v-btn elevation=false class="appmenu" :ripple="false" :class="ismain2().color" to="/youtube">영상보기</v-btn>
        <!-- <v-btn elevation=false class="appmenu" :ripple="false" :class="ismain2().color" to="/community">게시판</v-btn> -->
      </v-toolbar-items>
      <v-spacer/>

      <v-toolbar-items style="position:fixed; right:0;">
        <a href="https://beta.wintersleeping.com/" style="margin-top:12px; font-weight: bold;">동면중의 운동도우미 v2 베타</a>

        <v-btn :class="ismain2().color" text to="/user/settings" rounded v-if="user.login">
          마이페이지
        </v-btn>
        <Account :color="ismain2().color" />
      </v-toolbar-items>
    </v-app-bar>

    <v-navigation-drawer app v-model="drawer" clipped  :width=200  v-if="lvl==1">
      <v-list dense>
        <template>
          <v-list-item v-if="lvl==1" class="subtitle-2">
            관리자메뉴
          </v-list-item>
          <v-divider v-if="lvl==1" />
          <v-list-item v-if="lvl==1" :key="10000" link to="/admin/order">
            <v-list-item-action>
              <v-icon>settings</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title >
                인증관리
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="lvl==1" :key="10001" link to="/admin/food">
            <v-list-item-action>
              <v-icon>settings</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title >
                음식관리
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="lvl==1" :key="10002" link to="/admin/member">
            <v-list-item-action>
              <v-icon>settings</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title >
                회원관리
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="lvl==1" :key="10007" link to="/admin/supplement">
            <v-list-item-action>
              <v-icon>settings</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title >
                보충제관리
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="lvl==1" :key="10008" link to="/admin/supplement/choice">
            <v-list-item-action>
              <v-icon>settings</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title >
                보충제선택관리
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="lvl==1" :key="10004" link to="/admin/chicken">
            <v-list-item-action>
              <v-icon>settings</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title >
                닭가슴살관리
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="lvl==1" :key="10006" link to="/admin/youtube">
            <v-list-item-action>
              <v-icon>settings</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title >
                영상관리
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="lvl==1" :key="10005" link to="/admin/bug">
            <v-list-item-action>
              <v-icon>settings</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title >
                오류신고관리
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="lvl==1" :key="10003" link to="/admin/notice">
            <v-list-item-action>
              <v-icon>settings</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title >
                공지관리
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="lvl==1" :key="10010" link to="/admin/alarm">
            <v-list-item-action>
              <v-icon>alarm</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title >
                알림보내기
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="lvl==1" :key="10011" link to="/admin/team">
            <v-list-item-action>
              <v-icon>settings</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title >
                팀 관리
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="lvl==1" :key="10012" link to="/admin/fund">
            <v-list-item-action>
              <v-icon>settings</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title >
                펀딩 관리
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>

    <v-main style="background-color:#efefef">
      <v-container fluid class="ma-0 pa-0" >
        <router-view class="main"/>
      </v-container>
    </v-main>

    <v-bottom-navigation
      app
      grow
      v-if="mobile"
      background-color="#FBDE44FF"
      class="elevation-0"
    >
      <v-btn to="/main">
        <span>체중관리</span>
        <v-icon>loyalty</v-icon>
      </v-btn>

      <v-btn to="/weight">
        <span>운동도우미</span>
        <v-icon>show_chart</v-icon>
      </v-btn>

      <v-btn to="/supplement">
        <span>보충제추천</span>
        <v-icon>thumb_up</v-icon>
      </v-btn>

      <v-btn to="/youtube">
        <span>영상보기</span>
        <v-icon>video_library</v-icon>
      </v-btn>

      <!-- <v-btn to="/community">
        <span>게시판</span>
        <v-icon>community</v-icon>
      </v-btn>       -->

    </v-bottom-navigation>

  </v-app>
</template>

<script>

import { mapGetters } from 'vuex'

import Account from "./User/Account"

export default {
  components: {
    Account,
  },
  props: {
    source: String,
  },
  computed: {
    ...mapGetters({
      user: "user",
      mobile: "mobile",
      data: "data",
      limited: "limited",
      lvl: "lvl",
      appbarcolor: "appbarcolor",
    })
  },

  data: () => ({
    intro: true,
    scroll: 0,
    drawer: null,
  }),
  created: async function () {
    this.$vuetify.theme.dark = false;
  },
  methods: {
    ismain: function() {
      let currentpage = this.$route.path;
      if ( currentpage == "/" ) {
        this.intro = true;
        if (this.scroll > 40) {
          //return "#28334AFF";
          // return "#ffffff";
          return "#141E30";
        } else {
          return "transparent";
        }
      } else {
        this.intro = false;
        //return "#28334AFF";
        // return "#ffffff";
        return "#141E30";
      }
    },
    ismain2: function() {
      let currentpage = this.$route.path;
      if ( currentpage == "/" ) {
        this.intro = true;
        if (this.scroll > 40) {
          return { color: "white--text" };
          // return { color: "black--text" };
        } else {
          return { color: "black--text" };
        }
      } else {
        this.intro = false;
        return { color: "white--text" };
        // return { color: "black--text" };
      }
    },
  }
}
</script>

<style scoped>

a {
  text-decoration: none;
}

#keep {
  /*background: #28334AFF; */
}

#keep .v-navigation-drawer__border {
  display: none;
}

.apptopbar {
  /* border-bottom: rgb(251, 222, 68) solid 1px !important; */
}

.apptopbar span {
  /*color: white;*/
}

.appmenu {
  background-color:transparent !important;
}

.v-btn.appmenu::before {
  background-color: transparent;
}

.v-btn.appmenu:hover::before,
.v-btn.appmenu:focus::before {
  font-weight: bold !important;
}

.v-btn--active.appmenu::before {
  background-color: transparent;
}

.v-btn--active.appmenu {
  font-weight: bold !important;
}

</style>