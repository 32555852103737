import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify';
import firebase from 'firebase/app'
import 'firebase/auth';
import 'firebase/analytics';
import 'firebase/firestore';

import api_conf from './config/config.json';

// import 'fullpage.js/vendors/scrolloverflow'
//import VueFullPage from 'vue-fullpage.js'

/* eslint-disable no-console */
/* eslint-disable no-unused-vars */

import store from "./store";
import XLSX from 'xlsx'

import moment from 'moment-timezone/moment-timezone'
moment.tz.add("Asia/Seoul|KST|0|0");
moment.locale('ko');

import Chart from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels';

import lodash from 'lodash';

import "@/assets/global.css"

Vue.config.productionTip = true;

Vue.prototype.$XLSX = XLSX;
Vue.prototype.$moment = moment;
Vue.prototype.$Chart = Chart;
Vue.prototype.$lodash = lodash;

// Initialize Firebase
firebase.initializeApp(api_conf.firebase);
firebase.analytics();


Vue.prototype.$firestore = firebase.firestore();

const app = new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
});

app.$mount('#app');